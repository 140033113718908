/**
 * 触发 window.resize
 */
export function triggerWindowResizeEvent() {
  let event = document.createEvent('HTMLEvents')
  event.initEvent('resize', true, true)
  event.eventType = 'message'
  window.dispatchEvent(event)
}

// /**
//  * 过滤对象中为空的属性
//  * @param obj
//  * @returns {*}
//  */
export function filterObj(obj) {
  if (typeof obj !== 'object') {
    return;
  }

  for (var key in obj) {
    if (obj.hasOwnProperty(key)
      && (obj[key] == null || obj[key] == undefined || obj[key] === '')) {
      delete obj[key];
    }
  }
  return obj;
}

// 生成首页路由
export function generateIndexRouter(data) {
  let indexRouter = [{
    path: '/',
    name: 'dashboard',
    //component: () => import('@/components/layouts/BasicLayout'),
    component: resolve => require(['@/components/layouts/GlobalLayout'], resolve),
    meta: { title: '首页' },
    redirect: '/',
    children: [
      ...generateChildRouters(data)
    ]
  }, {
    "path": "*", "redirect": "/404", "hidden": true
  }]
  console.log('indexRouter---------------', indexRouter);
  return indexRouter;
}

// // 生成嵌套路由（子路由）
function generateChildRouters(data) {
  const routers = [];
  for (var item of data) {
    let component = "";
    if (item.routePath.indexOf("layouts") >= 0) {
      component = "components/" + item.routePath; //
    } else {
      component = "views/" + item.routePath;
    }

    let menu = {
      path: item.routePath,
      name: item.routeName,
      redirect: '',
      component: resolve => require(['@/' + component + '.vue'], resolve),
      hidden: '',
      //component:()=> import(`@/views/${item.component}.vue`),
      icon: item.icon,
      orderNo: item.orderNo,
      keepAlive: 'false'
    }
    if (item.alwaysShow) {
      menu.alwaysShow = true;
      menu.redirect = menu.path;
    }
    if (item.children && item.children.length > 0) {
      menu.children = [...generateChildRouters(item.children)];
    }
    //--update-begin----author:scott---date:20190320------for:根据后台菜单配置，判断是否路由菜单字段，动态选择是否生成路由（为了支持参数URL菜单）------
    //判断是否生成路由
    if (item.route && item.route === '0') {
      //console.log(' 不生成路由 item.route：  '+item.route);
      //console.log(' 不生成路由 item.path：  '+item.path);
    } else {
      routers.push(menu);
    }
    //--update-end----author:scott---date:20190320------for:根据后台菜单配置，判断是否路由菜单字段，动态选择是否生成路由（为了支持参数URL菜单）------
  }
  return routers
}

//转化为纯数字 up_number(" 2132,2134324.423 ") => "21322134324.423"
export function up_number(str) {
  return str.toString().trim().replace(/\,/g, "");
}

//转化金额 返回格式化后的数据 保留两位小数（四舍五入）  trans_money1("123.42343","3") 或 trans_money1("123.42343")
export function trans_money1(num, dec) {
  if (!num) {
    return '';
  }
  var _dec = "2";
  if (dec) {
    if (dec == "auto") {
      num = up_number(num).split(".");
    } else {
      _dec = dec;
      num = parseFloat(up_number(num)).toFixed(_dec).toString().split(".");
    }
  } else {
    num = parseFloat(up_number(num)).toFixed(_dec).toString().split(".");
  }
  num[0] = num[0].replace(new RegExp('(\\d)(?=(\\d{3})+$)', 'ig'), "$1,");
  return num.join(".");
}

//转化金额 返回格式化后的数据 保留两位小数（不四舍五入）  trans_money1("123.42343","3") 或 trans_money1("123.42343")
export function trans_money2(num, dec) {
  var _dec = "2";
  var reg;
  if (dec) {
    if (dec == "auto") {
      num = up_number(num).split(".");
    } else {
      _dec = dec;
      reg = RegExp("(\\.\\d{" + _dec + "})\\d+$");
      num = parseFloat(up_number(num).replace(reg, "$1")).toFixed(_dec).toString().split(".");
    }
  } else {
    reg = RegExp("(\\.\\d{" + _dec + "})\\d+$");
    num = parseFloat(up_number(num).replace(reg, "$1")).toFixed(_dec).toString().split(".");
  }
  num[0] = num[0].replace(new RegExp('(\\d)(?=(\\d{3})+$)', 'ig'), "$1,");
  return num.join(".");
}

//转化卡号 返回格式化后的数据  trans_cardnum("3114124134323213X") => "3114 1241 3432 3213X"
export function trans_cardnum(str) {
  return str.replace(/[\s]/g, '').replace(/(\d{4})(?=\d)/g, "$1 ");
}

//转化重点隐藏 返回格式化后的数据   trans_star("adas31141asda13Xas") => "a****************s"   trans_star("adaaesffdss",4,5) => "adaa**ffdss"
export function trans_star(str, num1, num2) {
  num1 = num1 || "1";
  num2 = num2 || "1";
  var num0 = str.length - num1 - num2;
  var reg = RegExp("^(\\S{" + num1 + "})\\S{" + num0 + "}(\\S{" + num2 + "})$");
  var s = "$1" + ("*************************************".substring(0, num0)) + "$2";
  return str.replace(reg, s);
}

export function addWan(integer, number, mutiple, decimalDigit) {
  var digit = getDigit(integer);
  if (digit > 3) {
    var remainder = digit % 8;
    if (remainder >= 5) { // ‘十万’、‘百万’、‘千万’显示为‘万’ 
      remainder = 4;
    }
    return Math.round(number / Math.pow(10, remainder + mutiple - decimalDigit)) / Math.pow(10, decimalDigit).toFixed(decimalDigit) + '万';
  } else {
    return Math.round(number / Math.pow(10, mutiple - decimalDigit)) / Math.pow(10, decimalDigit).toFixed(decimalDigit);
  }
}

export function getDigit(integer) {
  var digit = -1;
  while (integer >= 1) {
    digit++;
    integer = integer / 10;
  }
  return digit;
}
export function addChineseUnit(number, decimalDigit) {
  decimalDigit = decimalDigit == null ? 2 : decimalDigit; //保留多少位小数，默认两位
  var integer = Math.floor(number); // 向下取整  45.98  =》 45
  var digit = getDigit(integer);  // 10的指数（次幂）
  var unit = [];
  var returnNumber;
  if (digit > 3) {  // 大于1万
    var multiple = Math.floor(digit / 8); // 多少亿
    if (multiple >= 1) {
      var tmp = Math.round(integer / Math.pow(10, 8 * multiple));
      unit.push(addWan(tmp, number, 8 * multiple, decimalDigit).toFixed(decimalDigit));
      for (var i = 0; i < multiple; i++) {
        unit.push('亿');
      }
      returnNumber = unit.join('');
    } else {
      returnNumber = addWan(integer, number, 0, decimalDigit);
    }

    var _num;
    var _unit;
    if (unit.length === 0) {
      _num = returnNumber.slice(0, returnNumber.length - 1);
      _unit = '万';
    } else {
      _num = (unit[0]).toString().slice(0, returnNumber.length - 1);
      _unit = returnNumber.slice(_num.length);
    }
    return returnNumber;
  } else {
    return number;
  }
}

// 对银行卡号进行处理显示前四位和后四位中间用 *，不包含空格
export function formatCard(value) {
    if(value&&value.length>8) {
        let reg = /^(\d{4})(\d*)(\d{4})$/;
        let str = value.replace(reg, (a, b, c, d)=> {
            return b + c.replace(/\d/g, "*") + d;
        });
        return str
    } else {
        return value
    }
}


// /**
//  * 深度克隆对象、数组
//  * @param obj 被克隆的对象
//  * @return 克隆后的对象
//  */
// export function cloneObject(obj) {
//   return JSON.parse(JSON.stringify(obj))
// }

// /**
//  * 随机生成数字
//  *
//  * 示例：生成长度为 12 的随机数：randomNumber(12)
//  * 示例：生成 3~23 之间的随机数：randomNumber(3, 23)
//  *
//  * @param1 最小值 | 长度
//  * @param2 最大值
//  * @return int 生成后的数字
//  */
// export function randomNumber() {
//   // 生成 最小值 到 最大值 区间的随机数
//   const random = (min, max) => {
//     return Math.floor(Math.random() * (max - min + 1) + min)
//   }
//   if (arguments.length === 1) {
//     let [length] = arguments
//   // 生成指定长度的随机数字，首位一定不是 0
//     let nums = [...Array(length).keys()].map((i) => (i > 0 ? random(0, 9) : random(1, 9)))
//     return parseInt(nums.join(''))
//   } else if (arguments.length >= 2) {
//     let [min, max] = arguments
//     return random(min, max)
//   } else {
//     return Number.NaN
//   }
// }

// /**
//  * 随机生成字符串
//  * @param length 字符串的长度
//  * @param chats 可选字符串区间（只会生成传入的字符串中的字符）
//  * @return string 生成的字符串
//  */
// export function randomString(length, chats) {
//   if (!length) length = 1
//   if (!chats) chats = '0123456789qwertyuioplkjhgfdsazxcvbnm'
//   let str = ''
//   for (let i = 0; i < length; i++) {
//     let num = randomNumber(0, chats.length - 1)
//     str += chats[num]
//   }
//   return str
// }

// /**
//  * 随机生成uuid
//  * @return string 生成的uuid
//  */
// export function randomUUID() {
//   let chats = '0123456789abcdef'
//   return randomString(32, chats)
// }

// /**
//  * 下划线转驼峰
//  * @param string
//  * @returns {*}
//  */
// export function underLine2CamelCase(string){
//   return string.replace( /_([a-z])/g, function( all, letter ) {
//     return letter.toUpperCase();
//   });
// }

// /**
//  * 判断是否显示办理按钮
//  * @param bpmStatus
//  * @returns {*}
//  */
// export function showDealBtn(bpmStatus){
//   if(bpmStatus!="1"&&bpmStatus!="3"&&bpmStatus!="4"){
//     return true;
//   }
//   return false;
// }

// /**
//  * 增强CSS，可以在页面上输出全局css
//  * @param css 要增强的css
//  * @param id style标签的id，可以用来清除旧样式
//  */
// export function cssExpand(css, id) {
//   let style = document.createElement('style')
//   style.type = "text/css"
//   style.innerHTML = `@charset "UTF-8"; ${css}`
//   // 清除旧样式
//   if (id) {
//     let $style = document.getElementById(id)
//     if ($style != null) $style.outerHTML = ''
//     style.id = id
//   }
//   // 应用新样式
//   document.head.appendChild(style)
// }

// /**
//  * 重复值验证工具方法
//  *
//  * 使用示例：
//  * { validator: (rule, value, callback) => validateDuplicateValue('sys_fill_rule', 'rule_code', value, this.model.id, callback) }
//  *
//  * @param tableName 被验证的表名
//  * @param fieldName 被验证的字段名
//  * @param fieldVal 被验证的值
//  * @param dataId 数据ID，可空
//  * @param callback
//  */
// export function validateDuplicateValue(tableName, fieldName, fieldVal, dataId, callback) {
//   let params = { tableName, fieldName, fieldVal, dataId }
//   api.duplicateCheck(params).then(res => {
//     res['success'] ? callback() : callback(res['message'])
//   }).catch(err => {
//     callback(err.message || err)
//   })
// }

// /**
//  * 如果值不存在就 push 进数组，反之不处理
//  * @param array 要操作的数据
//  * @param value 要添加的值
//  * @param key 可空，如果比较的是对象，可能存在地址不一样但值实际上是一样的情况，可以传此字段判断对象中唯一的字段，例如 id。不传则直接比较实际值
//  * @returns {boolean} 成功 push 返回 true，不处理返回 false
//  */
// export function pushIfNotExist(array, value, key) {
//   for (let item of array) {
//     if (key && (item[key] === value[key])) {
//       return false
//     } else if (item === value) {
//       return false
//     }
//   }
//   array.push(value)
//   return true
// }